@font-face {
  font-family: 'reader400';
  font-weight: 400;
  src: url(assets/fonts/Reader.ttf) format('truetype');
}
@font-face {
  font-family: 'reader500';
  font-weight: 500;
  src: url(assets/fonts/Reader-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'reader700';
  font-weight: 700;
  src: url(assets/fonts/Reader-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'sharp300';
  font-weight: 300;
  src: url(assets/fonts/SharpSansNo2-Book.ttf) format('truetype');
}
@font-face {
  font-family: 'sharp600';
  font-weight: 600;
  src: url(assets/fonts/SharpSansNo2-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'sharp700';
  font-weight: 700;
  src: url(assets/fonts/SharpSansNo2-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'sharp800';
  font-weight: 800;
  src: url(assets/fonts/SharpSansNo2-Extrabold.ttf) format('truetype');
}
@font-face {
  font-family: 'sharp900';
  font-weight: 900;
  src: url(assets/fonts/SharpSansNo2-Black.ttf) format('truetype');
}

/* playbook */
@font-face {
  font-family: 'Reader';
  font-weight: '400';
  src: url('assets/fonts/Reader.otf') format('opentype');
}

@font-face {
  font-family: 'Reader-Medium';
  font-weight: '500';
  src: url('assets/fonts/Reader-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Reader-Bold';
  font-weight: '700';
  src: url('assets/fonts/Reader-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ReaderPro-Italic';
  font-weight: '400';
  src: url('assets/fonts/ReaderPro-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'ReaderPro-MediumItalic';
  font-weight: '500';
  src: url('assets/fonts/ReaderPro-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'ReaderPro-BoldItalic';
  font-weight: '700';
  src: url('assets/fonts/ReaderPro-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SharpSansDisplayNo2-Medium';
  font-weight: '500';
  src: url('assets/fonts/SharpSansDisplayNo2-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SharpSansDisplayNo2-Semibold';
  font-weight: '600';
  src: url('assets/fonts/SharpSansDisplayNo2-Semibold.otf')
    format('opentype');
}

@font-face {
  font-family: 'SharpSansDisplayNo2-Bold';
  font-weight: '700';
  src: url('assets/fonts/SharpSansDisplayNo2-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SharpSansDisplayNo2-MediumOblique';
  font-weight: '500';
  src: url('assets/fonts/SharpSansDisplayNo2-MediumOblique.otf')
    format('opentype');
}

@font-face {
  font-family: 'SharpSansDisplayNo2-SemiboldOblique';
  font-weight: '600';
  src: url('assets/fonts/SharpSansDisplayNo2-SemiboldOblique.otf')
    format('opentype');
}

@font-face {
  font-family: 'SharpSansDisplayNo2-BoldOblique';
  font-weight: '700';
  src: url('assets/fonts/SharpSansDisplayNo2-BoldOblique.otf')
    format('opentype');
}
