html,
body,
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  margin: 0;
  font-family: 'reader400', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: normal;
  background-color: #f2f2f2; /* gray */
  color: #37424a; /* carbon */
}

.no-pad {
  padding: 0 !important;
}
.top-0 {
  top: 0 !important;
}

a:focus-visible,
button:focus-visible,
.Mui-focusVisible,
.MuiCardActionArea-focusHighlight {
  outline: #201c21 auto thick; /* gray[800] */
  outline-offset: 3px;
}
